

var contentEN = {
  "aboutme" : {
    "name" : "About me",
    "content" : 
<>
<p>
Hi 👋, I'm <a rel="noreferrer" target="_blank" href="https://discord.com/users/728590937441304586">Moonboy</a>,<boybr/>
I am a self learning programmer
</p>
<p>
I have developed things like discord bots, apis , website and npm packages</p>

</>
  },


  "skills" : {
    "name" : "Skills", 
    "content" : 
<>
<p>
• Javascript 
</p>
<p>
• Typescript
</p>
<p>
• Python
</p>
<p>
• Nodejs
</p>
<p>
• HTML5
</p>
</>
  },


  "contact" : {
    "name" : "Contact",
    "content" : 
<>
<p>Here my contact info:</p>
<p> 💻 <a rel="noreferrer" target="_blank" href="https://github.com/A-cute-blob">My Github</a></p>
<p> 👔 <a rel="noreferrer" target="_blank" href="https://discord.com/users/728590937441304586">My discord</a></p>
    <p> 📨 <a href="mailto:moonboy@moonboy.live"> moonboy@moonboy.live </a></p>
</>
  }
}


export default contentEN
